<script>
import taxonomyForm from "@/views/menu/taxonomy/taxonomyForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    taxonomyService: "taxonomyService"
  }
})
export default class TaxonomyDetail extends mixins(taxonomyForm) {
  formId = "detail-taxonomy-form";

get decoratedElements() {
    return this.allReadonlyFields;
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle () {
    return this.translations.pageTitles.taxonomies_detail;
  }

  async afterCreate () {
    this.model = await this.taxonomyService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
